import { GET, POST } from "@/config/backend";

export default {
  async getPlans({ commit }, price) {
    const response = await GET(`order-subscription/plans`, {
      params: { price },
    });
    commit("setPlans", response.data.plans);
    return response;
  },

  async suspendSubscription({ commit }, orderReference) {
    try {
      commit("setSubscriptionUpdating", true);
      const response = await POST(
        `orders/${orderReference}/subscription/suspend`
      );
      commit("setSubscriptionUpdating", false);
      return response;
    } catch (error) {
      commit("setSubscriptionUpdating", false);
      throw error;
    }
  },

  async cancelSubscription({ commit }, orderReference) {
    commit("setSubscriptionUpdating", true);
    const response = await POST(`orders/${orderReference}/subscription/cancel`);
    commit("setSubscriptionUpdating", false);
    return response;
  },

  async resumeSubscription({ commit }, orderReference) {
    try {
      commit("setSubscriptionUpdating", true);
      const response = await POST(
        `orders/${orderReference}/subscription/resume`
      );
      commit("setSubscriptionUpdating", false);
      return response;
    } catch (error) {
      commit("setSubscriptionUpdating", false);
      throw error;
    }
  },

  async resumeSubscriptionSchedule({ commit }, orderReference) {
    try {
      commit("setSubscriptionUpdating", true);
      const response = await POST(
        `orders/${orderReference}/subscription-schedule/resume`
      );
      commit("setSubscriptionUpdating", false);
      return response;
    } catch (error) {
      commit("setSubscriptionUpdating", false);
      throw error;
    }
  },

  async cancelAutomaticReEngagementSubscription({ commit }, orderReference) {
    try {
      commit("setSubscriptionUpdating", true);
      const response = await POST(
        `orders/${orderReference}/subscription-schedule/cancel-automatic-re-engagement`
      );
      commit("setSubscriptionUpdating", false);
      return response;
    } catch (error) {
      commit("setSubscriptionUpdating", false);
      throw error;
    }
  },
};
